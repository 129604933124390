import React from 'react'

import Layout from '../components/layout'

const About = () => (
  <Layout>
 	 <h1>About me</h1>
     <p>
 	    My name is Max Bonnefin and I'm a Software Engineer based in the East      Midlands, UK.<br/>I am passionate about solving problems and learning new things.<br/>In my free time, I enjoy reading, going to concerts and tinkering with tech. 
	</p>
	<p>You can reach me via <a href="mailto:max@bonnef.in">email</a>.</p>
    <br />
    <h1>About this site</h1>
    <p>This site was made with <a href="https://www.gatsbyjs.org">Gatsby.js</a> a static site generator based on <a href="https://www.reactjs.org">React</a>.</p>
	<p>It's hosted in <a href="https://aws.amazon.com/">AWS</a> and deployed via a <a href="https://docs.gitlab.com/ee/ci/">GitLab CI</a> pipeline.</p>
  </Layout>
)

export default About;
